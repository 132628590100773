window.onload = function () {
    let mobileBtn = document.querySelector('.header-btn');
    let menu = document.querySelector('.header-menu');
    let info = document.querySelector('.header-info');

    mobileBtn.addEventListener('click', function (e) {
        e.preventDefault()
        info.classList.toggle('open');
        menu.classList.toggle('open');
        mobileBtn.classList.toggle('open');

    });

    document.querySelectorAll('.accordion-header').forEach((accordion) => accordion.addEventListener('click', () => {
        if (accordion.parentNode.classList.contains('active')) {
            accordion.parentNode.classList.toggle('active')
        }

        else {
            document.querySelectorAll('.accordion-header').forEach(accordion => accordion.parentNode.classList.remove('active'))
            accordion.parentNode.classList.add('active')
        }

    }))
}


